var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "v-container",
        [
          _c("PageTitle", {
            attrs: {
              "previous-location": "",
              "page-title": "Appeared Exam History",
            },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { xs: "12", md: "10" } },
                _vm._l(_vm.examHistory.exams, function (exam, i) {
                  return _c(
                    "v-container",
                    { key: i, staticClass: "mb-2" },
                    [_c("m-exam-history-list-item", { attrs: { exam: exam } })],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }