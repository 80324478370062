var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("m-base-card", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.exam.title) + " ")]
        },
        proxy: true,
      },
      {
        key: "col-1",
        fn: function () {
          return [
            _c("div", [
              _c("p", { staticClass: "text-center my-3" }, [
                _vm._v("Answered: " + _vm._s(_vm.exam.answered)),
              ]),
              _c("p", { staticClass: "text-center my-3" }, [
                _vm._v("Corrected: " + _vm._s(_vm.exam.correct)),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "col-2",
        fn: function () {
          return [
            _c("div", [
              _c(
                "p",
                { staticClass: "mb-0", staticStyle: { "font-size": "10px" } },
                [_vm._v("Score")]
              ),
              _c("p", { staticStyle: { "font-size": "14px" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.exam.score) +
                    " out of " +
                    _vm._s(_vm.exam.total_points) +
                    " "
                ),
              ]),
            ]),
            _c("div", [
              _c(
                "p",
                { staticClass: "mb-0", staticStyle: { "font-size": "10px" } },
                [_vm._v("Date appeard")]
              ),
              _c("p", { staticStyle: { "font-size": "14px" } }, [
                _vm._v(" " + _vm._s(_vm.exam.start_at.split("T")[0]) + " "),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "col-3",
        fn: function () {
          return [
            _c(
              "router-link",
              {
                staticClass:
                  "text-center my-3 d-flex justify-center black--text",
                attrs: {
                  disabled: !_vm.exam.is_evaluated,
                  to: {
                    name: "exam-history-details",
                    params: { examId: _vm.exam.id },
                  },
                },
              },
              [_vm._v("DETAILS")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }