import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL);
import { checkStatus } from "/global/utils/helpers";

export const ExamService = {
  /**
   * @return Promise<AxiosResponse>
   * @param batch_id
   * @param opts
   */
  list(batch_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    return $backend
      .get("students_list_shared_exams", {
        params: { batch_id },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  /**
   * @return Promise<AxiosResponse>
   * @param batch_id
   * @param opts
   */
  list_exams_with_all_informations(batch_id, opts = {}) {
    // todo maybe do some transformation. Not needed for now
    return $backend
      .get("students_list_shared_all_exams", {
        params: { batch_id },
        ...opts,
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },

  /**
   * @return Promise
   * @param batchId
   * @param examId
   * @param headers
   */
  // async startExam(batchId, examId, headers = {}) {
  //   return $backend
  //     .post("teachers_post_shares", { batch_id: batchId, exam_id: examId })
  //     .then(checkStatus)
  //     .then(r => r.data.content);
  // },
};

export default ExamService;
