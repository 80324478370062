<template>
  <v-container v-if="renderNow">
    <PageTitle previous-location page-title="Appeared Exam History">
    </PageTitle>
    <v-row>
      <v-col xs="12" md="10">
        <v-container
          v-for="(exam, i) in examHistory.exams"
          :key="i"
          class="mb-2"
        >
          <m-exam-history-list-item :exam="exam"></m-exam-history-list-item>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import examService from "#ef/exam/services/ExamService";
import PageTitle from "@ef/global/components/PageTitle";
import MExamHistoryListItem from "#ef/exam/components/MExamHistoryListItem";

export default {
  name: "ExamHistory",
  components: { MExamHistoryListItem, PageTitle },
  props: {
    batchId: String,
  },
  data() {
    return {
      examHistory: undefined,
      renderNow: false,
    };
  },
  computed: {
    examRowCols() {
      return this.shareExamPanel ? 8 : 9;
    },
  },
  watch: {},
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ) {
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    await this.fetchSharedExams();
    await this.getExamHistory();
    this.renderNow = true;
  },
  methods: {
    async fetchSharedExams() {
      let content = await examService.list(this.batch_id);
      this.exams = content.exams;
    },
    async getExamHistory() {
      try {
        this.examHistory = await this.$store.dispatch("studentExam/getExamHistory", {
          batchId: this.batchId,
        });
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          e.message,
        ]);
      }
    },
  },
};
</script>
