<template>
  <m-base-card>
    <template v-slot:title>
      {{ exam.title }}
    </template>
    <template v-slot:col-1>
      <div>
        <p class="text-center my-3">Answered: {{ exam.answered }}</p>
        <p class="text-center my-3">Corrected: {{ exam.correct }}</p>
      </div>
    </template>
    <template v-slot:col-2>
      <div>
        <p class="mb-0" style="font-size: 10px">Score</p>
        <p style="font-size: 14px">
          {{ exam.score }} out of {{ exam.total_points }}
        </p>
      </div>
      <div>
        <p class="mb-0" style="font-size: 10px">Date appeard</p>
        <p style="font-size: 14px">
          {{ exam.start_at.split("T")[0] }}
        </p>
      </div>
    </template>
    <template v-slot:col-3>
      <router-link
        :disabled="!exam.is_evaluated"
        class="text-center my-3 d-flex justify-center black--text"
        :to="{ name: 'exam-history-details', params: { examId: exam.id } }"
        >DETAILS</router-link
      >
    </template>
  </m-base-card>
</template>

<script>
import MBaseCard from "~ef/components/MBaseCard";
export default {
  name: "MExamHistoryListItem",
  components: { MBaseCard },
  props: {
    exam: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables";

.mk-divider {
  border-color: #cfcfcf;
  border-width: 1px;
}
.mk-divider-col {
  border-color: #cfcfcf;
  border-width: 1px;
}
.mk-exam-card {
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  transition: all 0.2s;
  background-color: white;
}

.mk-card-col1 {
  background: #f5f5f5;
  width: 200px;
  border-bottom-left-radius: 5px;
}
.mk-card-col2 {
  background: #f5f5f5;
  width: 200px;
  border-bottom-right-radius: 5px;
}
.on-hover {
  box-sizing: border-box;
  border: 1px solid $primary;
  border-left: 8px solid $primary;
  box-shadow: 2px 2px 7px #cfcfcf;
  transform: scaleX(1.02);

  .mk-divider {
    border-color: $primary;
    border-width: 0.5px;
  }
}
</style>
